import { render, staticRenderFns } from "./down-card-content.vue?vue&type=template&id=4660a0ca&scoped=true&"
import script from "./down-card-content.vue?vue&type=script&lang=ts&"
export * from "./down-card-content.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4660a0ca",
  null
  
)

export default component.exports