import { render, staticRenderFns } from "./SentimentSection.vue?vue&type=template&id=437b6516&scoped=true&"
import script from "./SentimentSection.vue?vue&type=script&lang=ts&"
export * from "./SentimentSection.vue?vue&type=script&lang=ts&"
import style0 from "./SentimentSection.vue?vue&type=style&index=0&id=437b6516&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "437b6516",
  null
  
)

export default component.exports