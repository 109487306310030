









































import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class VideoComponent extends Vue{
  groupObjectId: string = this.$route.params.groupObjectId;
  createVideo: boolean = false;
  videoURI: string = '';
  audioExt: string[] = ['.m4a'];
  audioCheck: boolean = false;

  mounted() {
    this.getVideo();
  }

  async getVideo(){
    const { data } = await this.axios.get(
        `/group/${this.groupObjectId}`
    );
    const {result, groupInfo} = data;
    if (result) {
      const {compositionComplete, compositionUpload} = groupInfo;
      this.createVideo = compositionComplete;
      if (compositionComplete) {
        const {Location} = compositionUpload;
        this.videoURI = Location;
        const _lastDot = Location.lastIndexOf('.');
        const _fileExt = Location.substring(_lastDot, Location.length).toLowerCase();

        setTimeout(() => {
          this.audioCheck = this.audioExt.includes(_fileExt);
        },100);
      }
    }
  }

  compositionDownload() {
    location.href =
        this.videoURI
  }
}
