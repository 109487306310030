












































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import DoughnutComponent from "@/components/chart/DoughnutComponent.vue";
import {IProject, PROJECT} from "@/interface/project";
import {GROUP, IGroup} from "@/interface/group";
import {IParticipant, PARTICIPANT} from "@/interface/participant";
import * as d3 from "d3";
import {schemeAccent, schemeSet3, schemeTableau10} from "d3-scale-chromatic";


@Component({
  components: {
    DoughnutComponent,
  }
})
export default class InfoComponent extends Vue{
  title: string = '';
  groupObjectId: string = this.$route.params.groupObjectId;
  project: IProject = PROJECT.getInitProject();
  group: IGroup = GROUP.getInitGroup();
  participants: IParticipant[] = [];
  moderatorName: string = '';
  private page = 1;
  private total = 0;
  private currentParticipants:IParticipant[]=[]

  genderData: any = {
    title:'성별',
    datasets: [
      {
        labels: ['남자', '여자'],
        backgroundColor: [
          'rgba(232,69,88,1)',
          'rgba(236,108,69,1)',],
        data: [60,30] ,
      },
    ],
  };

  ageData: any = {
    title:'연령',
    datasets: [
      {
        labels: ['20대', '30대', '40대'],
        backgroundColor: [
          'rgba(232,69,88,1)',
          'rgba(236,108,69,1)',
          'rgba(247,172,27,1)',
        ],
        data: [60,30,10],
      },
    ],
  };

  async created() {
    await this.loadGroupReportData();
    await this.drawChart();
  }
  async mounted(){

  }

  async loadGroupReportData() {
    const { data } = await this.axios.get(
        `/report/${this.groupObjectId}`
    );
    const { result, groupInfo, projectInfo, participantInfo, groupParticipantInfo } = data;

    if (result === true) {
      this.moderatorName = groupParticipantInfo.filter((p)=> p.role === 'moderator')[0].name;
      this.project = projectInfo;
      this.group = groupInfo;
      this.title = `[ ${this.project.title} ] ${this.group.title}`;
      this.$emit('setTitle',this.title);
      this.$emit('setGroupInfo', this.group);
      this.participants = groupParticipantInfo.filter(
          (p) => p.status === 'completed'
      ).map((p)=>{
        return{
          ...p,
          _id: p.participantObjectId
        }
      });
      let part: IParticipant[] = [];
      this.participants.forEach((p) =>{
        participantInfo.forEach((parti) =>{
          if(parti.email === p.email){
            part.push(parti);
          }
        });
      });
      this.participants = part;
      this.total = Math.ceil(this.participants.length /5)
      this.loadParticipant()
      this.genderData.datasets[0].data = this.calculateGender();
      this.ageData.datasets[0].data = this.calculateAge();
    }
  }

  loadParticipant(){
    this.currentParticipants=[]
    this.currentParticipants.push(...this.participants.slice((this.page-1)*5,this.page*5))
  }
  calPage(num:number){
    this.page=this.page+num;
    if(!this.page){
      this.page=this.total;
    }else if(this.page> this.total){
      this.page=1;
    }
    this.loadParticipant()
  }

  calculatorGroupTime(length: number): string {
    const hour = Math.floor(length / 60);
    const hourStr = hour < 1 ? '' : `${hour}시간 `;
    const minute = length % 60;
    const minuteStr = minute === 0 ? '' : `${minute}분`;
    return hourStr + minuteStr;
  }

  drawChart(){
    const width=150;
    const height=150;
    const age = d3.select('#age').attr('width', width+50).attr('height', height+50).append("g").attr('transform', `translate(${(width / 2)+30},${(height / 2)+50})`);
    const gender = d3.select('#gender').attr('width', width+50).attr('height', height+50).append("g").attr('transform', `translate(${(width / 2)+30},${(height / 2)+50})`);
    const color = d3.scaleOrdinal()
        .range(["#DC3912", "#3366CC", "#109618", "#FF9900", "#990099"]);

    const pie = d3.pie()

    const arc:any = d3.arc()
        .outerRadius( 30)
        .innerRadius(Math.min(width,height) / 2 - 1);

    /**
     * 타이틀
     * */
    gender.append("text")
        .attr("x", 0)
        .attr("y",  -110)
        .attr("text-anchor", "middle")
        .style("font-size", "16px")
        .text(this.genderData.title);
    age.append("text")
        .attr("x", 0)
        .attr("y",  -110)
        .attr("text-anchor", "middle")
        .style("font-size", "16px")
        .text(this.ageData.title);

    /**
     * 범례
     * */
    gender.append('g').attr('class', 'legend').selectAll('text').data(pie(this.genderData.datasets[0].labels)).enter()
        .append('rect').attr('width',10).attr('height',10).attr('y',(d,i)=>{
      return -100
    }).attr('x', function(d, i) { return -(width / 2)+(i*50)+30; }).attr('fill', (d, i) => d3.schemeTableau10[i]);
    gender.append('g').attr('class', 'legend').selectAll('text').data(pie(this.genderData.datasets[0].labels)).enter()
        .append('text').text((d,i)=> {return d.data.toString()}).attr('y',(d,i)=>{
      return -90
    }).attr('x', function(d, i) { return -(width / 2)+(i*50)+40; })

    age.append('g').attr('class', 'legend').selectAll('text').data(pie(this.ageData.datasets[0].labels)).enter()
        .append('rect').attr('width',10).attr('height',10).attr('y',(d,i)=>{
      return -100
    }).attr('x', function(d, i) { return -(width / 2)+(i*50)+10; }).attr('fill', (d, i) => d3.schemeTableau10[i]);

    age.append('g').attr('class', 'legend').selectAll('text').data(pie(this.ageData.datasets[0].labels)).enter()
        .append('text').text((d,i)=> {return d.data.toString()}).attr('y',(d,i)=>{
      return -90
    }).attr('x', function(d, i) { return -(width / 2)+(i*50)+20; })

    /**
     * 차트
     * */
    const genderArcs = gender.selectAll('arc').data(pie(this.genderData.datasets[0].data)).enter().append('g');
    const ageArcs = age.selectAll('arc').data(pie(this.ageData.datasets[0].data)).enter().append('g');
    ageArcs.append('path')
        .attr('d', arc)
        .attr('fill', (d, i) => d3.schemeTableau10[i]);
    genderArcs.append('path')
        .attr('d', arc)
        .attr('fill', (d, i) => d3.schemeTableau10[i]);

    /**
     * 라벨
     * */
    const text = d3.arc()
        .outerRadius(Math.min(width,height) / 2 - 10 - 10)
        .innerRadius(Math.min(width,height) / 2 - 10 - 10);

    let total = this.genderData.datasets[0].data.reduce(
        (ac, cr) => ac + cr,
        0,
     )
    genderArcs.append("text")
        .attr("fill", "white")
        .attr("transform", function(d:any) { return "translate(" + text.centroid(d) + ")"; })
        .attr("dy", "5px")
        .attr("font", "10px")
        .attr("style", "font-family: Pretendard-Light;")
        .attr("text-anchor", "middle")
        .text((d) =>{ return Number(this.calculatePercentage(total,Number(d.data)))>0 ? `${this.calculatePercentage(total,Number(d.data))}%` : ''});

    total = this.ageData.datasets[0].data.reduce(
        (ac, cr) => ac + cr,
        0,
    )
    ageArcs.append("text")
        .attr("fill", "white")
        .attr("transform", function(d:any) { return "translate(" + text.centroid(d) + ")"; })
        .attr("dy", "5px")
        .attr("font", "10px")
        .attr("style", "font-family: Pretendard-Light;")
        .attr("text-anchor", "middle")
        .text((d) =>{ return Number(this.calculatePercentage(total,Number(d.data)))>0 ? `${this.calculatePercentage(total,Number(d.data))}%` : ''});

  }

  randomColor(): string {
    return `#${Math.round(Math.random() * 0xffffff).toString(16)}`;
  }

  calculateGender(){
    let manCnt = 0;
    this.participants.forEach((part)=>{
      if(part.gender === 'man'){
        manCnt++;
      }
    });
    const womenCnt = this.participants.length - manCnt;
    return [manCnt, womenCnt];
  }

  calculateAge(){
    let today = new Date();
    let ageList = [0,0,0];
    this.participants.forEach((part)=>{
      let aDay = new Date(this.$moment(part.birthDate).format('YYYY-MM-DD'));
      let years = today.getFullYear() - aDay.getFullYear() +1;
      if(years > 19 && years < 30){
        ageList[0]++;
      } else if (years > 29 && years < 40){
        ageList[1]++;
      } else {
        ageList[2]++;
      }
    });
    return ageList;
  }

  calculatePercentage(total:number, num:number):string{
    let percentage = ((num/total)*100)
    return Number.isInteger(percentage) ? percentage.toString() : percentage.toFixed(1)
  }

}

