

import {Component, Mixins, Prop } from "vue-property-decorator";
import { HorizontalBar, mixins } from 'vue-chartjs';
import {ChartOptions} from "chart.js";

@Component({
  extends: HorizontalBar,
  mixins: [mixins.reactiveProp],
})
export default class BarComponent extends Mixins(mixins.reactiveProp, HorizontalBar) {
  @Prop() chartData;
  @Prop() options;

  mounted () {
    this.renderChart(this.chartData, this.options)

  }
}
