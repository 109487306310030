














import {Component, Vue} from "vue-property-decorator";
import * as d3 from "d3";
import DataTable from "@/components/report/DataTable.vue";


@Component({components:{DataTable}})
export default class NetworkSection extends Vue{
  private gongTableData:Array<{keyword:string; frequency:number}>=[]
  private nodeListData=[]
  private networkData:Array<{source:string; target:string; frequency:number;}>=[]
  private simulation: d3.Simulation<any, any>

  private backColors=["#324DDD", "#475FE1","#5B71E4","#7083E8","#8494EB","#99A6EE","#ADB8F1","#C2CAF5","#D6DBF8","#EBEEFC"]
  private size=[32,32,28,28,21,21,15,15,12,12]
  async created(){
    await this.getData()
    this.drawChartData()
  }
  async getData(){
    try {
      const{data}= await this.axios.get(`report/summary/${this.$route.params.groupObjectId}/network`);
      if(data) {
        const nodeList = data[0].data[0].nodeList;
        const gongTable = data[0].data[0].gongTable;
        const networkData = data[0].data[0].networkData;

        this.gongTableData = gongTable.map((item: { keyword_1: string; keyword_2: string; frequency: number }) => {
          return {keyword: `${item.keyword_1}-${item.keyword_2}`, frequency:item.frequency}
        })
        this.nodeListData = nodeList.map((item, index)=>{
          console.log(item.node)
          return {
            id:item.node,
            degree:item.degree,
            size:this.size[this.calculateRangeIndex(nodeList.length,index)],
            color:this.backColors[this.calculateRangeIndex(nodeList.length,index)]
          }
        })
        this.networkData =  networkData.map(item=>{
          return {source:item.source, target:item.target, frequency:Number(item.frequency)}
        })
      }
    }catch (e){
      console.log(e)
    }
  }
  drawChartData(){
    const width=850;
    const height=400;

    const svg = d3.select("#network").append("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;")
        .attr("text-anchor", "middle")
        .style("margin", "auto");

    const gHolder = svg.append("g")
        .attr("class", "g-holder");

    const link = gHolder.append("g")
        .attr("stroke", "#8494EB")
        .attr("stroke-opacity", 0.6)
        .selectAll("line")
        .data(this.networkData)
        .join("line")
        .attr("stroke-width", (d) => d.frequency );


    const node = svg.append("g")
        .attr("class", "circle-node-holder")
        .selectAll("g")
        .data(this.nodeListData)
        .enter()
        .append("g")
        .each(function(d: any){
          d3.select(this)
              .append("circle")
              .attr("r", (d:any)=> d.size)
              .attr("fill", (d:any)=>d.color)

          d3.select(this)
              .append("text").text(d.id)
              .attr("dy",6)
              .attr("font-size",(d: any)=>{return d.size>28 ? "14px" : "12px" } )
              .style("text-anchor","middle")
              .style("fill", (d: any)=>{return d.size>21 ? "white" : "#324DDD" })
              .attr("class", "node-label")
        })

    /*const link = d3.select("#network>svg")
        .selectAll()
        .data(this.networkData)
        .join("line")
        .attr("stroke-width", d => d.frequency)
        .attr("stroke", "#ff0000")
        .attr("stroke-opacity", 1);

    const node = d3.select("#network>svg")
        .selectAll("circle")
        .data(this.nodeListData)
        .enter()
        .append('circle')
        .attr("r", (d:any) => d.degree/10)
        .attr("stroke", "#FFF")
        .attr("stroke-width", 1)
        .attr('title',d=>d.id)
        .style('fill',"#0000ff")*/


    /*node.call(d3.drag().on("start",this.dragStarted)
        .on("drag", this.dragged)
        .on("end", this.dragEnded)
    )*/
    node.call((selection: any) => {
      const dragBehavior = d3.drag()
          .on("start", this.dragStarted)
          .on("drag", this.dragged)
          .on("end", this.dragEnded);
      selection.call(dragBehavior);
    })

    /*const forceLink = d3.forceLink()
        .id(function (d: any) {
          return d.id;
        })
        .distance(options.linkDistance)
        .strength(options.linkStrength)
    ;*/


    this.simulation = d3.forceSimulation()
        .nodes(this.nodeListData)
        .force("charge", d3.forceManyBody())
        .force("link", d3.forceLink(this.networkData).id((d:any)=>d.id))
        .force("center", d3.forceCenter(width / 2, (height / 2)-10))
      .force("collide",d3.forceCollide().radius( function(d:any){ return d.degree/3}) );
    this.simulation
        .on("tick", ticked);



    // 4. forceSimulation 그림 업데이트 함수
    function ticked() {
      link
          .attr("x1", function (d:any) { return d.source.x; })
          .attr("y1", function (d:any) { return d.source.y; })
          .attr("x2", function (d:any) { return d.target.x; })
          .attr("y2", function (d:any) { return d.target.y; });
      node.attr("transform", function(d:any) { return "translate("+d.x+","+ d.y+")"; });
    }
  }
  dragStarted(event, d) {
    if (!event.active) this.simulation.alphaTarget(0.3).restart();
    event.subject.fx = event.subject.x;
    event.subject.fy = event.subject.y;
  }

  dragged(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;
  }

  dragEnded(event, d) {
    if (!event.active) this.simulation.alphaTarget(0);
    event.subject.fx = null;
    event.subject.fy = null;
  }


  calculateRangeIndex(total, current){
    const percent = Math.floor(current/total*10);
    console.log(current/total*10)
    return percent;
  }
}
