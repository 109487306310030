














import { Component, Prop, Vue } from 'vue-property-decorator';
import { DownloadCardBoardType, DownloadType } from '@/interface/download';
import DownCardLayout from '@/components/download/card/detail/down-card-layout.vue';
import DownCardTop from '@/components/download/card/detail/down-card-top.vue';
import DownCardContentSpeech from '@/components/download/card/down-card-content-speech.vue';
import DownCardContentExcel from '@/components/download/card/down-card-content-excel.vue';
import DownCardContentPdf from '@/components/download/card/down-card-content-pdf.vue';
import DownCardContentVideo from "@/components/download/card/down-card-content-video.vue";
import dayjs from 'dayjs';
import { toJpeg } from 'html-to-image';
import { addImage, firstPageAdd, pdfInit } from '@/components/download/card/download';

@Component({
  components: {
    DownCardContentVideo,
    DownCardContentPdf,
    DownCardContentExcel,
    DownCardContentSpeech,
    DownCardTop,
    DownCardLayout,
  },
})
export default class DownCard extends Vue {
  @Prop() type: DownloadType;
  private groupObjectId: string = this.$route.params.groupObjectId;
  private load: boolean = false;
  reportFirstPage = require('@/assets/REPORT.jpeg');

  get title(): string {
    if (this.isExcel) return 'Excel';
    if (this.isSpeech) return 'SpeechText';
    if (this.isPdf) return '보고서 PDF';
    if (this.isVideo) return '좌담회 영상';
    return '';
  }

  get color(): DownloadCardBoardType {
    if (this.isExcel) return 'green';
    if (this.isSpeech) return 'purple';
    if (this.isPdf) return 'red';
    if (this.isVideo) return 'blue';
    return '';
  }

  get LinkBtn(): Function {
    if (this.isExcel) return this.downExcel;
    if (this.isSpeech) return this.downTextCheck;
    if (this.isPdf) return this.downPDF;
    if (this.isVideo) return this.downVideo;
    return this.error;
  }

  get isExcel(): boolean {
    return this.type === 'excel';
  }

  get isSpeech(): boolean {
    return this.type === 'speech';
  }

  get isPdf(): boolean {
    return this.type === 'pdf';
  }

  get isVideo(): boolean {
    return this.type === 'video';
  }

  async downTextCheck() {
    const { data } = await this.axios.post(`/download/stt`, {
      groupId: this.groupObjectId,
    });
    if (data.result) {
      await this.downText();
    }
    return data.result;
  }

  async downText() {
    try {
      this.load = true;
      const { data } = await this.axios.get(`/download/stt/${this.groupObjectId}`, {
        responseType: 'blob',
      });
      this.dataCheck(data);
      this.downFile(data, 'SpeechToText');
      this.load = false;
    } catch (e) {
      this.$toast.error(e.message);
    } finally {
      this.load = false;
    }
  }

  async downExcel() {
    try {
      this.load = true;
      const { data } = await this.axios.get(`/download/excel/${this.groupObjectId}`, {
        responseType: 'blob',
      });
      this.dataCheck(data);
      this.downFile(data, 'EXCEL');
      this.load = false;
    } catch (e) {
      this.$toast.error(e.message);
    } finally {
      this.load = false;
    }
  }

  async downPDF() {
    try {
      const infoNode = document.getElementById('info');
      const resultNode = document.getElementById('result');
      const summaryNode = document.getElementById("summary");

      const doc = pdfInit();
      const marginInfo = {
        marginTop: 10,
      };

      firstPageAdd(this.reportFirstPage, doc);
      if (summaryNode && resultNode && infoNode) {
        const infoImage = await this.getDataInfo(infoNode)
        const summaryImage = await this.getDataInfo(summaryNode);
        const resultImage = await this.getDataInfo(resultNode);
        addImage(infoImage, doc, marginInfo, 60);
        addImage(resultImage, doc, marginInfo,resultNode.offsetHeight/8)
        addImage(summaryImage, doc, marginInfo,summaryNode.offsetHeight/8)
      }
      doc.save(`결과요약정보_${this.groupObjectId}.pdf`);
    } catch (e) {
      this.$toast.error('PDF 생성 중 오류가 발생했습니다.');
    }
  }
  async downVideo() {
    const {data} = await this.axios.get(
        `/group/${this.groupObjectId}`
    );
    const {result, groupInfo} = data;
    if (result) {
      const {compositionComplete, compositionUpload} = groupInfo;
      if (compositionComplete) {
        const {Location, Key} = compositionUpload;
        //location.href =Location;
        const fileName = Key.split("/").at(-1) || 'video';

        fetch(Location)
            .then(response => response.blob())
            .then(blob => {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            })
            .catch(console.error);
      }
    }
  }

    async getDataInfo(chartElement: HTMLElement): Promise<string> {
    return await new Promise((resolve) => {
      toJpeg(chartElement).then((png) => {
        resolve(png);
      });
    });
  }

  dataCheck(data: Blob) {
    if (!data || data.type === 'application/json') throw new Error('파일을 정상적으로 생성하지 못했습니다.');
  }

  downFile(data: Blob, name: string) {
    const fileName = `${name}_요약${dayjs().format('YY-MM-DD HH:mm')}.xlsx`;
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  error() {
    console.log('TOP-BUTTON 에러 발생');
  }
}
