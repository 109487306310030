











import { Component, Vue } from 'vue-property-decorator';
import DownCardContent from '@/components/download/card/detail/down-card-content.vue';

@Component({ components: { DownCardContent } })
export default class DownCardContentVideo extends Vue {}
