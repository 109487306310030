





















import {Component, Vue} from "vue-property-decorator";

import KeywordSection from "@/components/report/KeywordSection.vue";
import SentimentSection from "@/components/report/SentimentSection.vue";
import NetworkSection from "@/components/report/NetworkSection.vue";

@Component({
  components: {KeywordSection,SentimentSection, NetworkSection}
})
export default class SummaryReport extends Vue{
  private summaryTextData:string= "";

  async created(){
    await this.getSummaryTextData()
  }
  async getSummaryTextData(){
    try{
      const {data} = await this.axios.get(`report/summary/${this.$route.params.groupObjectId}/text`)
      if(data){
        this.summaryTextData = data[0].data[0]
      }
    }catch (e) {
      console.log(e)
    }
  }
}
