











import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { DownloadCardBoardType } from '@/interface/download';

@Component({ components: {} })
export default class DownCardLayout extends Vue {
  @PropSync('load') syncLoad: boolean;
  @Prop() color: DownloadCardBoardType;

  get borderColor(): string {
    return `border-left-${this.color}`;
  }
}
