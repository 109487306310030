






import { Component, Vue } from 'vue-property-decorator';
import { DownloadType } from '@/interface/download';
import DownCard from '@/components/download/card/down-card.vue';

@Component({
  components: { DownCard },
})
export default class DownList extends Vue {
  private downCardInfo: DownloadType[] = ['excel', 'speech', 'pdf' , 'video'];
}
