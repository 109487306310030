var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[(_vm.questionData.length > 0 && _vm.answer.length > 0)?_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('b-card',{attrs:{"no-body":"","id":"result"}},[_c('b-card-body',[_c('b-card-title',[_c('h5',[_c('b',[_vm._v("좌담회 결과보기")])])]),_c('b-row',_vm._l((_vm.questionData),function(survey,idx){return _c('b-col',{key:idx,staticClass:"p-5",attrs:{"cols":"4"}},[(survey.questionType!=='text')?_c('h6',{staticClass:"pl-3 mt-3",staticStyle:{"font-family":"Pretendard-Regular"}},[_vm._v(_vm._s(survey.questionNumber)+". "+_vm._s(survey.questionContents))]):_vm._e(),_c('b-row',{staticClass:"my-auto"},[_c('b-col',{attrs:{"cols":"12"}},[(survey.questionType==='radioSet' && survey.use)?_c('LineComponent',{key:_vm.chartData.length,attrs:{"chartData":_vm.chartData[idx],"options":{

                        legend: {
                          display: false,
                        },
                        scales: {
                        xAxes: [{
                          gridLines: {
                            display: false,
                          },
                          ticks: {
                            stepSize: 1,
                            min: 0,
                            max: _vm.answer.length
                          }
                        }],
                        yAxes: [{
                          ticks: {
                            stepSize: 20,
                            min: 0,
                            max: 100
                          }
                        }]
                      },
                  }}}):_vm._e(),(survey.questionType==='radio' && survey.use)?_c('PieComponent',{key:_vm.chartData.length,attrs:{"chart-data":_vm.chartData[idx],"options":{
                    maintainAspectRatio: false,
                  }}}):_vm._e(),(survey.questionType==='check' && survey.use)?_c('BarComponent',{key:survey.change,attrs:{"chart-data":_vm.chartData[idx],"options":{
                        legend:{
                          display: false
                        },
                        scales: {
                          xAxes: [{
                            gridLines: {
                              display: false,
                            },
                            ticks: {
                              stepSize: 1,
                              min: 0,
                              max: _vm.answer.length
                            }
                          }]
                        },
                        plugins: {
                            legend: {
                                labels: {

                                }

                            }
                        }
                  }}}):_vm._e()],1)],1)],1)}),1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }