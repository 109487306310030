export namespace CHART{
    export function getInitIScatterData(){
        return {
            datasets: []
        }
    }
    export function getInitIScatterDataSets(){
        return {
            borderColor: 'rgb(255, 99, 132)',
            data: [],
            label: '',
            showLine: true,
            fill: false,
            tension: 0,
        }
    }
    export function getInitIBarData(){
        return{
            labels: [],
            datasets: [],
        }
    }
    export function getInitIBarDataSets(){
        return {
            axis: 'y',
            label: '',
            data: [],
            fill: false,
            backgroundColor: [],
            borderColor: [],
            borderWidth: 0,
        }
    }
    export function getInitIPieData(){
        return{
            labels: [],
            datasets: [],
        }
    }
    export function getInitIPieDatasets(){
        return {
            label: '',
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
        }
    }
    export function getInitIBubbleData(){
        return {
            datasets: [],
        }
    }
    export function getInitIBubbleDatasets(){
        return {
            label: '',
            data: [],
            backgroundColor: '',
        }
    }
    export function getInitIBubbleXYR(){
        return {
            x: 0,
            y: 0,
            r: 0,
        }
    }

    export function getInitILineData(){
        return {
            labels: [],
            datasets: []
        }
    }

    export function getInitILineDatasets(){
        return{
            label: '',
            data: [],
            fill: false,
            borderColor: '',
            tension: 0.1
        }
    }
}
export interface IScatterData{
    datasets: IScatterDatasets[];
}

export interface IScatterDatasets{
    borderColor: string;
    data: IScatterDataXY[];
    label: string;
    showLine: boolean;
    fill: boolean;
    tension: number;
}

export interface IScatterDataXY{
    x: number;
    y: number;
}

export interface IBarData{
    labels: string[];
    datasets: IBarDatasets[];
}

export interface IBarDatasets{
    axis: string;
    label: string;
    data: number[];
    fill: boolean;
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
}

export interface IPieData{
    labels: string[];
    datasets: IPieDatasets[]
}

export interface IPieDatasets{
    label: string;
    data: number[];
    backgroundColor: string[];
    hoverOffset: number;
}

export interface IBubbleData{
    datasets: IBubbleDatasets[];
}

export interface IBubbleDatasets{
    label: string;
    data: IBubbleDataXYR[];
    backgroundColor: string;
}

export interface IBubbleDataXYR{
    x: number;
    y: number;
    r: number;
}

export interface ILineData{
    labels: string[];
    datasets: ILineDataSets[];
}

export interface ILineDataSets{
    label: string;
    data: number[];
    fill: boolean;
    borderColor: string;
    tension: number;
}
