import { render, staticRenderFns } from "./down-list.vue?vue&type=template&id=16a65c70&scoped=true&"
import script from "./down-list.vue?vue&type=script&lang=ts&"
export * from "./down-list.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a65c70",
  null
  
)

export default component.exports