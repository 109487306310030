

























































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {IGroup} from "@/interface/group";
import ScatterComponent from "@/components/chart/ScatterComponent.vue";
import PieComponent from "@/components/chart/PieComponent.vue";
import BarComponent from "@/components/chart/BarComponent.vue";
import LineComponent from "@/components/chart/LineComponent.vue";
import {IAnswer, IAnswerData} from "@/interface/answer";
import {
  CHART,
  IBarData,
  IBarDatasets,
  IBubbleData,
  IBubbleDatasets,
  IBubbleDataXYR,
  ILineData,
  ILineDataSets,
  IPieData,
  IPieDatasets,
  IScatterData,
  IScatterDatasets,
  IScatterDataXY,
} from "@/interface/chart";
import getInitIScatterData = CHART.getInitIScatterData;
import getInitIScatterDataSets = CHART.getInitIScatterDataSets;
import getInitIBarData = CHART.getInitIBarData;
import getInitIBarDataSets = CHART.getInitIBarDataSets;
import {IQuestion, ISurvey} from "@/interface/survey";
import getInitIPieData = CHART.getInitIPieData;
import getInitIPieDatasets = CHART.getInitIPieDatasets;
import getInitILineData = CHART.getInitILineData;
import getInitILineDatasets = CHART.getInitILineDatasets;


@Component({
  components:{
    ScatterComponent,
    PieComponent,
    BarComponent,
    LineComponent,
  }
})
export default class ResultChartComponent extends Vue{
  @Prop() group: IGroup;
  answer: IAnswer[] = [];
  answerData: IAnswerData[] = [];
  chartData: any[] = [];
  questionData: IQuestion[] = [];

  defaultColor: string[] = [
    'rgba(232,69,88,1)',
    'rgba(236,108,69,1)',
    'rgba(247,172,27,1)',
    'rgba(254,234,59,1)',
    'rgba(170,206,54,1)',
    'rgba(5,170,114,1)',
    'rgba(0,165,167,1)',
    'rgba(0,146,203,1)',
    'rgba(34,127,196,1)',
    'rgba(116,124,187,1)',
    'rgba(177,119,178,1)',
    'rgba(220,106,155,1)'];


  /*randomColor(): string {
    return `#${Math.round(Math.random() * 0xffffff).toString(16)}`;
  }*/

  created(){
    if(this.group._id){
      this.getAnswer();
    }
  }

  async getAnswer(){
    const { data } = await this.axios.get(`/answer/${this.group._id}`);
    this.answer = data;
    if(this.answer.length){
      this.answer?.forEach((a)=>{
        a.data?.forEach((d)=>{
          this.answerData.push(d);
        })
      });
    }
    this.setChartData();
  }

  async setChartData(){
    this.chartData = [];
    const { data } = await this.axios.get(`/question/${this.group._id}`);
    const { questions } = data;
    this.questionData = questions.filter((q)=>
        q.questionType !== 'text'
    );

    const questionsNotText = questions.filter((q)=>
        q.questionType !== 'text'
    );
    questionsNotText?.forEach((q, idx)=>{
      const {questionType, questionNumber} = q;
      if(questionType === 'radioSet'){
        this.chartData[idx] = this.calculateLine(q, questionNumber, idx);
      }else if(questionType === 'check') {
        this.chartData[idx] = this.calculateBar(q, questionNumber, idx);
      }else if(questionType === 'radio'){
        this.chartData[idx] = this.calculatePie(q, questionNumber, idx);
      }else{
        console.log(questionType, idx, questionNumber)
      }
    });
  }

  /*calculateScatter(question:IQuestion, qNum: string, idx: number){
    let scatter: IScatterData = getInitIScatterData();

    let sDataSets: IScatterDatasets = getInitIScatterDataSets();

    let sData: IScatterDataXY[] = [];
    const qData = this.answerData.filter( d => d.module===qNum );
    if(!qData.length){
      this.questionData[idx].use = false;
    }

    const xAxes = question.examples.map( e =>{
      return e.text;
    });
    this.scatterXAxes.push( xAxes );
    const counts = question.examples.length;
    let counting: number[] = [];
    for(let i=0; i<counts; i++){
      counting.push(0);
    }
    qData.forEach((q)=>{
      counting[Number(q.value)-1]++;
    });
    for( let i=0; i<counts; i++){
      let data = {
        x: i+1,
        y: counting[i]
      }
      sData.push(data);
    }
    sDataSets.data = sData;
    scatter.datasets = [sDataSets];
    return scatter;

  }*/

  calculateBar(question:IQuestion, qNum: string, idx: number){
    /*console.log(question);
    let bar: IBarData = getInitIBarData();
    let counting: number[] = [];

    question.examples.forEach((e, i) => {
      //bar.labels.push(e.text);
      counting.push(0);
    })
    const qData = this.answerData.filter( d => d.module===qNum );
    if(!qData.length){
      this.questionData[idx].use = false;
    }
    let qDataList: string[] = [];
    qData.forEach((q)=>{
      if(q.value.length){
        for(let i=0; i<q.value.length; i++){
          qDataList.push(q.value[i])
        }
      }
    });
    qDataList.forEach((q)=>{
      counting[Number(q)-1]++;
    });
    ///
    question.examples.forEach((e, i) => {
      let bDataSets: IBarDatasets = getInitIBarDataSets();
      bDataSets.label = e.text;
      let colors: string[] = [];
      question.examples.forEach((ee, ii) =>{
        colors.push(this.defaultColor[i]);
      })
      bDataSets.backgroundColor = colors;
      bDataSets.borderColor = colors;
      //
      const countList = [...counting];
      countList.forEach((c,idx)=>{
        if(idx !== i){
          countList[idx] = 0;
        }
      });
      bDataSets.data = countList;
      console.log(bDataSets,'))))')
      bar.datasets.push(bDataSets);
    });*/



    let bar: IBarData = getInitIBarData();
    let bDataSets: IBarDatasets = getInitIBarDataSets();
    let counting: number[] = [];
    const { examples } = question;
    const colors: string[] = [];
    examples.forEach((ex, i)=>{
      colors.push(this.defaultColor[i]);
      bar.labels.push(ex.text);
      counting.push(0);
    });

    const qData = this.answerData.filter( d => d.module===qNum );
    if(!qData.length){
      this.questionData[idx].use = false;
    }
    let qDataList: string[] = [];
    qData.forEach((q)=>{
      if(q.value.length){
        for(let i=0; i<q.value.length; i++){
          qDataList.push(q.value[i])
        }
      }
    });
    qDataList.forEach((q)=>{
      counting[Number(q)-1]++;
    });
    bDataSets.data = counting;
    bDataSets.backgroundColor = colors;
    bar.datasets = [bDataSets];
    return bar;
  }

  calculatePie(question: IQuestion, qNum: string, idx: number){
    let pie: IPieData = getInitIPieData();
    let pDatasets: IPieDatasets = getInitIPieDatasets();
    pDatasets.label = qNum;
    let counting: number[] = [];
    const { examples } = question;
    examples.forEach((ex, i)=>{
      pie.labels.push(ex.text);
      counting.push(0);
      pDatasets.backgroundColor.push(this.defaultColor[i]);
    });

    const pData = this.answerData.filter( d => d.module===qNum );
    if(!pData.length){
      this.questionData[idx].use = false;
    }
    pData.forEach((p)=>{
      counting[Number(p.value) -1]++;
    });
    pDatasets.data = counting;
    pie.datasets = [pDatasets];
    return pie;
  }

  calculateLine(question:IQuestion, qNum: string, idx: number){
    let line: ILineData = getInitILineData();

    let lDataSets: ILineDataSets = getInitILineDatasets();
    lDataSets.borderColor = 'rgb(255, 99, 132)';

    line.labels = question.examples.map( e =>{
      return e.text;
    })
    const counts = question.examples.length;
    let counting: number[] = [];
    for(let i=0; i<counts; i++){
      counting.push(0);
    }
    const lData = this.answerData.filter( d => d.module===qNum );
    if(!lData.length){
      this.questionData[idx].use = false;
    }
    lData.forEach((q)=>{
      counting[Number(q.value)-1]++;
    });
    lDataSets.data = counting;

    line.datasets = [lDataSets];
    return line;
  }



}


