

































import {Component, Vue} from "vue-property-decorator";
import * as d3 from "d3";
import cloud from 'd3-cloud';

@Component({})
export default class SentimentSection extends Vue{
  private sentimentData:Array<{keyword:string; frequency:number; sentimentType:'negative'|'neutral'| 'positive'}>=[]

  private positiveFontColors=["#0056ff","#1a3276","#3bc2cb","#2858ff","#409aff","#555c63"]
  private negativeFontColors=["#e94724","#e13e4f","#8c5847","#ff5c2a","#ff993a","#555c63"]
  private neutralFontColors=["#0a785e","#11ad46","#5f9fb4","#4eccae","#43acd9","#555c63"]
  private fontSize=[36,28,20,16,14,14]

  async created(){
    await this.getData()
    this.drawWordCloudChart();
  }
  async getData(){
    try {
      const {data} = await this.axios.get(`report/summary/${this.$route.params.groupObjectId}/sentiment`)
      if(data){
        this.sentimentData=data[0].data
      }
    }catch (e){
      console.log(e)
    }
  }
  drawWordCloudChart(){
    const positiveList:{text:string;size:number;value:number}[]=[];
    const neutralList:{text:string;size:number;value:number}[]=[];
    const negativeList:{text:string;size:number;value:number}[]=[];
    this.sentimentData.forEach(item=>{
      if(item.sentimentType==="negative"){
        negativeList.push({text:item.keyword,size:(36/25)*(25-negativeList.length+1)<14?14:(36/25)*(25-negativeList.length+1), value:item.frequency})
      }else if(item.sentimentType==="positive"){
        positiveList.push({text:item.keyword, size:(36/9)*(9-positiveList.length+1)<14?14:(36/9)*(9-positiveList.length+1),value:item.frequency})
      }else{
        neutralList.push({text:item.keyword, size:(36/40)*(40-neutralList.length+1)<14?14:(36/40)*(40-neutralList.length+1),value:item.frequency})
      }
    })

    this.createWord(positiveList,0,50, d3.interpolateBlues, "#positive").start();
    this.createWord(neutralList,0,50, d3.interpolateGreens,"#neutral").start();
    this.createWord(negativeList,0,50, d3.interpolateReds,"#negative").start();
  }

  createWord(list, min, max, colorFn:Function, selector){
    const width= 300;
    const height= 250;
    return cloud()
        .size([width, height])
        .words(list)
        .rotate(function() { return ~~(Math.random() * 2) * 90; }) // 0 또는 90도로 회전
        .padding(3)
        .spiral("rectangular")
        .font("Pretendard-SemiBold")
        //.fontSize((d:any, i)=> {  return this.fontReSizing(min,list.length)(list.length-i) })
        .fontSize((d:any, i)=> {
          const index = this.calculateFontIndex(list.length, i);
          return this.getFontSize(index)
        })
        .on("end",(words)=>{
            d3.select(selector)
                .append("svg")
                .attr("width", width)
                .attr("height",height)
                .append("g")
                .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
                .selectAll("text")
                .data(words)
                .enter()
                .append("text")
                .style("font-size", (d:any)=> { return d.size + "px"; })
                .style("font-weight", 700)
                .style("font-family", "Pretendard-Regular")
                .attr("text-anchor", "middle")
                .style("fill", (d:any, i)=>{
                  const index = this.calculateFontIndex(list.length, i);
                  return this.getColor(selector, index);
                  //return colorFn((list.length-i)/list.length);
                })
                .attr("transform", function(d:any) {
                  return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
                })
                .text(function(d:any) { return d.text; });
        })
  }

  getColor(selector:"#positive"|"#negative"|"#neutral",index:number):string{
    if(selector==="#positive"){
      return this.positiveFontColors[index]
    }else if(selector==="#negative"){
      return this.negativeFontColors[index]
    }else{
      return this.neutralFontColors[index]
    }
  }
  getFontSize(index:number):number{
      return this.fontSize[index]
  }
  calculateFontIndex(total, current){
    const percent = current/total*100;
    if(percent<=3) {
      return 0;
    }else if(percent<=13){
      return 1;
    }else if(percent<=33){
      return 2;
    }else if(percent<=63){
      return 3;
    }else if(percent<=83){
      return 4;
    }else{
      return 5;
    }
  }
  /*fontReSizing(min, max){
    return d3.scaleLinear()
        .domain([
          1,
          max
        ])
        .range([14,36]).clamp(true)
  }*/
}
