








































































import { Component, Vue } from 'vue-property-decorator';
import DownList from '@/components/download/down-list.vue';
import InfoComponent from '@/components/chart/InfoComponent.vue';
import VideoComponent from '@/components/chart/VideoComponent.vue';
import ResultChartComponent from '@/components/chart/ResultChartComponent.vue';
import { GROUP, IGroup } from '@/interface/group';
import SummaryReport from "@/components/report/SummaryReport.vue";

@Component({
  components: {
    DownList,
    InfoComponent,
    VideoComponent,
    ResultChartComponent,
    SummaryReport
  },
})
export default class GroupReport extends Vue {
  title: string = '';
  group: IGroup = GROUP.getInitGroup();
  menuFlag: boolean = true;
  sideMenus: Array<{
    name: string;
    href: string;
    icon: string;
    active: boolean;
  }> = [
    {
      name: '좌담회 정보',
      href: '#info',
      icon: 'fa-info-circle',
      active: false,
    },
    {
      name: '좌담회 영상',
      href: '#interview',
      icon: 'fa-users',
      active: false,
    },
    {
      name: '다운로드',
      href: '#download',
      icon: 'fa-file',
      active: false,
    },
  ];

  private isSummary:boolean=false;
  created(){
    //this.isSummary = this.isSummaryData()
    this.isSummaryData()
  }

  menuMove(target: string, index: number) {
    this.sideMenus.forEach((item) => {
      item.active = false;
    });
    this.sideMenus[index].active = true;
    document.querySelector(target)?.scrollIntoView();
  }


  onScroll() {
    const scrollTop = window.scrollY || window.pageYOffset;
    const currentPos = scrollTop + window.innerHeight / 2;
    for (let i = 0; i < this.sideMenus.length; i++) {
      //this.sideMenus[i].active = false;
      const eleTop = document.querySelector(this.sideMenus[i].href)?.getBoundingClientRect().top || 0;
      console.log(i, eleTop, currentPos);
      if (eleTop < 0) {
        this.sideMenus[i].active = false;
      } else if (eleTop < currentPos) {
        this.sideMenus[i].active = true;
      }
    }
  }

  beforeDestroy() {}

  async isSummaryData(){
    try {
      const groupId = this.$route.params.groupObjectId;
      const res= await this.axios.get(`/report/summary/${groupId}`)
      this.isSummary=res.data
    }catch (e){
      this.isSummary=false;
    }


  }
  setTitle(titleStr: string) {
    this.title = titleStr;
  }

  setGroupInfo(groupInfo: IGroup) {
    this.group = groupInfo;
  }
}
