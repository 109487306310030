import { render, staticRenderFns } from "./NetworkSection.vue?vue&type=template&id=0c89ff45&scoped=true&"
import script from "./NetworkSection.vue?vue&type=script&lang=ts&"
export * from "./NetworkSection.vue?vue&type=script&lang=ts&"
import style0 from "./NetworkSection.vue?vue&type=style&index=0&id=0c89ff45&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c89ff45",
  null
  
)

export default component.exports