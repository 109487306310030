
import {Component, Mixins, Prop } from "vue-property-decorator";
import { Line, mixins } from 'vue-chartjs';

@Component({
  extends: Line,
  mixins: [mixins.reactiveProp],
})
export default class LineComponent extends Mixins(mixins.reactiveProp, Line) {
  @Prop() chartData;
  @Prop() options;

  mounted () {
    this.chartData.datasets.forEach((answer,i)=> {
      let total = 0;
      answer.data.forEach(item=>{
        total+=item
      })
      answer.data.forEach((item,j)=>{
        this.chartData.datasets[i].data[j] = Math.round(item/total * 100)
      })
    })
    this.renderChart(this.chartData, this.options);
  }


}
