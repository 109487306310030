
















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { DownloadType } from '@/interface/download';
import dayjs from 'dayjs';
// import * as fs from "fs";

@Component({ components: {} })
export default class DownCardTop extends Vue {
  @Prop() header: string;
  @Prop() type: DownloadType;
  @Prop() down: Function;
}
