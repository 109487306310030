import jsPDF from 'jspdf';

export class Download {}

const MARGIN_TOP_DEFAULT = 10;
const PAGE_TITLE_NEW = 200;
const PAGE_HEIGHT = 297;

const marginLeft = 10;
const marginBottom = 10;
const width = 190;

const titleHeight = 20;
const contentHeight = 50;

export function pdfInit(): jsPDF {
  return new jsPDF('p', 'mm', 'a4');
}

export function firstPageAdd(image: any, doc: jsPDF) {
  doc.addImage(image, 'PNG', 0, 0, 210, 297);
  doc.addPage();
}

export function addImage(dataUrl: string, doc: jsPDF, marginInfo: { marginTop: number }, height: number) {
  const marginTopCheck = marginInfo.marginTop + height + marginBottom;
  if (marginTopCheck > PAGE_HEIGHT) {
    doc.addPage();
    marginInfo.marginTop = 10;
  }
  doc.addImage(dataUrl, 'JPEG', marginLeft, marginInfo.marginTop, width, height);
  marginInfo.marginTop += height + marginBottom;
}
