


















import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class DataTable extends Vue{
  @Prop() header!:string[]
  @Prop() body:Array<{keyword:string; frequency:number}>
  @Prop() firstRank:number


}
