

import {Component, Mixins, Prop } from "vue-property-decorator";
import { Doughnut, mixins } from 'vue-chartjs';

@Component({
    extends: Doughnut,
    mixins: [mixins.reactiveProp],
})
export default class DoughnutComponent extends Mixins(mixins.reactiveProp, Doughnut) {
    @Prop() chartData;
    @Prop() options;

    mounted () {
        this.renderChart(this.chartData, this.options);
    }
}
