

import {Component, Mixins, Prop } from "vue-property-decorator";
import { Pie, mixins } from 'vue-chartjs';

@Component({
  extends: Pie,
  mixins: [mixins.reactiveProp],
})
export default class BubbleComponent extends Mixins(mixins.reactiveProp, Pie) {
  @Prop() chartData;
  @Prop() options;

  mounted () {
    this.renderChart(this.chartData, this.options);
  }

}
