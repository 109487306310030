




















import * as d3 from "d3";
import {Component, Vue} from "vue-property-decorator";
import DataTable from "@/components/report/DataTable.vue";
import {HierarchyNode} from "d3-hierarchy";

@Component({components: {DataTable}})
export default class KeywordSection extends Vue{
  keywordData:Array<{keyword:string; frequency:number}>=[];

  async created(){
    await this.getData();
    await this.drawKeywordChart()
  }

  async getData(){
    const{data}= await this.axios.get(`report/summary/${this.$route.params.groupObjectId}/keyword`)
    if(data){
      this.keywordData = data.find(element=> element.type === 'keyword').data.slice(0,20)

    }
  }

  async drawKeywordChart(){
    const width= 550;
    const height= 400;

    const data = this.keywordData.map(item=>{
      return {name:item.keyword, value:item.frequency}
    })
    const values = data.map(d => d.value);
    const min = Math.min.apply(null, values);
    const max = Math.max.apply(null, values);
    const total = data.length;

    const root:HierarchyNode<{ children: { name: string; value: number; }[]}>
        = d3.hierarchy({children:data});
    root.sum(function (d:any) { return d.value; });
    const pack = d3.pack<{ children: { name: string; value: number; }[]}>()
        .size([width, height])
        .padding(1);
    pack(root);

    root.sum((d:any)=> {return d.value})

    const svg = d3.select("#keyword").append("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [-20, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;")
        .attr("text-anchor", "middle")
        .style("margin", "auto");

    const node = svg
        .selectAll()
        .data(root.leaves())
        .enter()
        .append("g")
        .attr("class","node")
        .attr("transform", (d:any) => `translate(${d.x},${d.y})`)

    node.append("circle")
        .attr("r", (d:any) => d.r)
        .style('fill', (item:any)=>{
          return this.getColor(item);
        })

    node.append("title")
        .text((d:any) => `${d.data.name}`).style("color","white");

    node.append("text")
        .attr("clip-path", (d:any) => `circle(${d.r})`)
        .text((d:any) =>  d.data.name ? `${d.data.name}` : "")
        .style("fill", "#ffffff")
        .style("font-size",
            (item:any)=>{
              return this.getFontSize(item.data.value, min, max, total);
            })
  }

  getFontSize(value, min, max, total) {
    const minPx = 6;
    const maxPx = 25;
    const pxRange = maxPx - minPx;
    const dataRange = max - min;
    const ratio = pxRange / dataRange;
    const size = Math.min(maxPx, Math.round(value * ratio) + minPx);
    return `${size}px`;
  }
  getColor(item){
    const value = Number(item.value);
    if(value<=10){
      return "#B4BEDD"
    }else if(value<=20){
      return "#9BA9D1"
    }else if(value<=40){
      return "#8193C6"
    }else if(value<=60){
      return "#687DBA"
    }else if(value<=100){
      return "#4F68AF"
    }else{
      return "#3652A3"
    }
  }
}
